import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { Button } from './common/button';
import OnboardingLayout from './onboarding/components/layout/onboarding-layout';

function ErrorApp() {
  const returnHome = () => {
    window.location.href = '/';
  };
  return (
    <Router>
      <Switch>
        <Route>
          <OnboardingLayout fullSizeContent>
            <div className='mt-5 px-10 flex flex-col items-start gap-6'>
              <div className='text-bedrock-serif-h1 text-bedrock-black'>
                404. Cannot find the page you're looking for
              </div>
              <div className='text-bedrock-p text-bedrock-black'>
                It could have been swallowed up in Khazad-dûm - we’re not sure.
              </div>
              <Button variant='primary' size='regular' label='Return home' onClick={returnHome} />
            </div>
          </OnboardingLayout>
        </Route>
      </Switch>
    </Router>
  );
}

export default ErrorApp;
