import React from 'react';
import ReactDOM from 'react-dom';
import { bootstrap } from './bootstrap';
import ErrorApp from './ErrorApp';

bootstrap();
ReactDOM.render(
  <React.StrictMode>
    <ErrorApp />
  </React.StrictMode>,
  document.getElementById('error-root')
);
